#menu{
  position: fixed;
  font-size: x-large;
  font-weight: bold;
  text-decoration: underline;
  text-align: left;
  background-color: white;
  width: 100%;
  height: 100%;
  padding-left: 10%;
}


.menu-item{
  margin: 1rem 0rem;
}