.app {
  // background: linear-gradient( rgba(0, 0, 0, 0.5) url("../public/field.jpeg");
  // background: linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url("../public/field.png");
  background: white;
  // background-position:center;
  // background-size: cover;
  // text-align: center;
  // color: white;
  // .selected{
  //   font-weight: 600;
  //   color:grey;
  // }
  .topbar{
    height: 5rem;
    min-height: 66px;
    background: white;
    display: flex;
    justify-content: space-between;
    align-items: center ;
    // margin: auto 1rem;
    font-size: x-large;
    position: fixed;
    width: 100%;
    top:0; 
    .menu{
      background-color: white;
      border-radius: 10%;
      margin: auto auto;
      margin-right: 1rem;
      font-size:xx-large;
      z-index: 9999;
    }
    .menu:hover{
      background-color: white;
    }
    .logo{
      margin: auto 5%;
      width: 4rem;
    }
  }
  .fade-in{
    opacity: 0;
    animation-name: fade-in;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
  
  .fade-out {
    opacity: 1;
    animation-name: fade-out;
    animation-duration: 1s;
    animation-fill-mode: forwards;
  }
  
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}

h1{
  font-size: xx-large;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.nav-bar{
  display: flex;
  background-color:white;
  margin-top: 0px;
  padding: 15px;
  .buttons{
    width: 50%;
    margin: auto auto;
    display: flex;
  }
  li{
    margin: 0rem auto;
    list-style: none;
    a{
      text-decoration: none;
      color: black;
      &:hover{
        color: grey;
        font-weight: 600;
      }
    }
  }
}