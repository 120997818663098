.main-page{
  color: black;
  margin: 5% 5%;
  .title{
    font-size: 64px;
    font-weight: bold;   
  }
  .location{
    font-size: 36px;
  }
  // .call-to-action{
  //   display:flex;
  //   height: 50px;
  //   width: 200px;
  //   border-radius: 30%;
  //   // border: 1px solid black;
  //   background-color: black;
  //   color: white;
  // }

  .call-to-action {
    background-color: #323232; /* Green */
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    transition-duration: 0.4s;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    margin-top: 1rem;
  }
  
  .call-to-action:hover {
    background-color: black; /* Green */
    color: white;
  }

  .info{
    margin-top: 2rem;
    .title{
      font-weight: bold;
      font-size: large;
      margin-bottom: 0.25rem;
    }
    font-weight: normal;
    font-size: medium;

  }
}