.contact{
  text-align: center;
  margin-top: 10rem;
  font-size: large;
  font-weight: bold;
}
.facebook{
  margin-top: 2rem;
  text-align: center;
  .facebook-logo{
    width: 50px;
    justify-content: center;
  }
}
