.leadership{
  width: 90%;
  height: 100%;
  margin: auto auto;
  text-align: left;
  background-color:rgba(255, 255, 255, 0.8) ;
  color: black;
  .title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto auto;
  }
  .pastors{
    .profile{
      margin-bottom: 10px;
      padding: 10px;
      border-radius: 5%;
      .pastor-photo{
        max-width: 900px;
        border-radius: 5%;
        height: auto;
        width: auto; /* ie8 */
      box-shadow: 5px 5px 5px 1px;

      }
    }

  }

}
